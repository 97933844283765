import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, } from "../../../core/components/ui/dialog";
import { Button } from "../../../core/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { setCategoryFolder, setUpdateCategory } from "../../../redux-store/reducers/chat-reducers";
import { Label } from "../../../core/components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../core/components/ui/tabs";
import { categoryPathSend } from "../../../shared/services/categoryPath";
import { ArrowLeft, Info } from "lucide-react";
import { categoryPath, clearCategoryPath } from "../../../redux-store/reducers/categoryPath-reducer";
import FileIconViewer from "../../../shared/components/file-icon-viewer/file-icon-viewer";
import floderIcon from '../../../assets/images/Others/Folder.png'
import { categoryFolder } from "../../../shared/models/categoryConfig-model";
import { Input } from "../../../core/components/ui/input";
import { Checkbox } from "../../../core/components/ui/checkbox";
import { title } from "process";

interface FileDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CategoryPop: React.FC<FileDialogProps> = ({ open, setOpen }) => {
  const dispatch: AppDispatch = useDispatch();

  const initialLoad = useRef(true);

  const { selectCategory } = useSelector((state: RootState) => state.chat);
  const { categoryPath } = useSelector((state: RootState) => state.categoryPath);

  const [folderStack, setFolderStack] = useState<string[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [selectCategoryObj, setCategoryObj] = useState<categoryFolder>();


  const [searchTerm, setSearchTerm] = useState("");

  // const categoryFolders = selectCategory?.categoryFolders ?? [];
  // console.log(selectCategory);
  // const referenceFolders = categoryFolders.filter(folder => folder.isReference);



  useEffect(() => {
    if (selectCategory && selectCategoryObj) {
      setCategoryObj(selectCategory.find((item) => item.title === selectCategoryObj.title))
    }
  }, [selectCategory]);

  useEffect(() => {
    if (initialLoad.current && selectCategory && selectCategory.length > 0) {
      setFolderStack([...folderStack, selectCategory[0].folder ?? '']);
      handleCategoryChange(selectCategory[0].title)
      dispatch(categoryPathSend(selectCategory[0].folder));
      initialLoad.current = false;
    }
  }, []);


  const handleFileSelect = (filePath: string, check: boolean) => {
    dispatch(setUpdateCategory({ filePath, check, title: selectCategoryObj?.title }))
  };



  const handleBack = () => {
    if (folderStack.length > 1) {
      const updatedStack = folderStack.slice(0, -1);
      const previousFolder = updatedStack[updatedStack.length - 1].replace('/drive/root:/', '') || null;
      setFolderStack(updatedStack);
      setSelectedFolder(previousFolder?.split('/').pop() ?? '');
      dispatch(categoryPathSend(previousFolder || ""));
    } else {
      dispatch(categoryPathSend(folderStack[0].replace('/drive/root:/', '') || ""));
      setFolderStack([]);
      setSelectedFolder(null);
    }
  };

  const handlefolderSelection = (folderPath: string) => {
    setSelectedFolder(folderPath.split('/').pop() ?? folderPath);
    setFolderStack([...folderStack, folderPath]);
    dispatch(categoryPathSend(folderPath.replace('/drive/root:/', '') || ""));
  }

  const handleCategoryChange = (selectedCategoryTitle: string) => {
    const selected_category = selectCategory?.find((item) => item.title == selectedCategoryTitle);
    setCategoryObj(selected_category)
    dispatch(categoryPathSend(selected_category?.folder || ""));
    setFolderStack([selected_category?.folder ?? '']);
    // setSelectedFolder(null);
    // dispatch(clearCategoryPath([]));
  };

  const SavePath = () => {
    dispatch(setCategoryFolder(selectCategory))
    setOpen(false);
    setFolderStack([]);
    setSelectedFolder(null);
    dispatch(clearCategoryPath([]));

  };

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = categoryPath.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderFolderContents = (folders: categoryFolder[], isPrimaryPath: boolean) => {
    return (
      <>
        <Label className="text-sm font-extrabold">Folder Contents</Label>
        <div className="flex flex-row">
          {categoryPath && categoryPath.length > 0 && (
            <div className="flex text-sm justify-center items-center w-full text-gray-500 ">
              <Input
                type="text"
                placeholder="Search items..."
                value={searchTerm}
                onChange={(event) => handleSearchChange(event)}
              />
            </div>
          )}
        </div>
        <div className="flex sm:max-w-[950px] max-h-[200px] lg:max-h-[330px] max-h-[70vh] max-w-[100vw] overflow-hidden overflow-y-auto">
          <div className="grid grid-cols-4 sm:grid-cols-4 lg:grid-cols-8 gap-4 mt-2">
            {categoryPath && categoryPath.length === 0 && selectedFolder && (
              <div className="flex text-sm absolute justify-center items-center w-full text-gray-500 ">
                <Info className="mr-2"></Info>
                Files or folders do not exist
              </div>
            )}


            {[...filteredItems]
              .sort((a, b) => a.type - b.type)
              .map((item: categoryPath, index: number) => (
                <div key={index} className="flex flex-col items-start cursor-pointer relative">
                  <div
                    className="relative p-5 bg-gray-200 rounded-lg hover:bg-gray-300">
                    <Checkbox
                      checked={selectCategoryObj?.paths.includes(item.path)}
                      onCheckedChange={(e: boolean) => {
                        handleFileSelect(item.path, e);
                      }}
                      className="absolute top-0 right-0 m-2"
                    />
                    <div>
                      {item.type === 0 ? (
                        <div className="flex-shrink-0 w-[40px] h-[40px] shadow-lg rounded-xl items-center flex flex-col justify-center" onDoubleClick={() => handlefolderSelection(item.path)}>
                          <img alt="Folder Icon" src={floderIcon} className="w-full h-full object-contain" />
                        </div>
                      ) : (
                        <div className="flex flex-col w-fit space-y-2 h-fit max-w-[75px]">
                          <div className="flex-shrink-0 w-[40px] h-[40px] shadow-lg rounded-xl">
                            <FileIconViewer className="w-full h-full" file={item.path} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <span className="text-xs mt-1 text-warp" title={item.name}>
                    {item.name.length > 15 ? `${item.name.slice(0, 12)}...` : item.name}
                  </span>
                </div>
              ))
            }
          </div>
        </div>
      </>
    )
  }

  const handleDialogClose = (isOpen: boolean) => {
    setOpen(isOpen);
    if (!isOpen) {
      setFolderStack([]);
      setSelectedFolder(null);
      dispatch(clearCategoryPath([]));
    }
  };

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} onOpenChange={handleDialogClose}>
        <DialogContent className="sm:max-w-[950px] lg:max-h-[630px] max-h-[90vh] max-w-[100vw] rounded">
          <DialogHeader className="flex flex-col items-left border-b">
            <DialogTitle className="font-extrabold text-lg">Category Folder Path</DialogTitle>
            <DialogDescription className="border-b pb-2">
              Your selected category folder path type below.
            </DialogDescription>
          </DialogHeader>

          <Tabs
            defaultValue={selectCategory && selectCategory.length > 0 ? selectCategory[0].title : ""}
            onValueChange={handleCategoryChange}
          >
            {folderStack.length > 1 && (
              <div className="flex items-center space-x-2">
                <Button variant="outline" size={'sm'} onClick={handleBack}>
                  <ArrowLeft />
                </Button>
                <span className="text-sm font-semibold">{selectedFolder}</span>
              </div>
            )}

            <TabsList className="bg-transparent p-1 rounded-lg flex space-x-2 justify-center items-center w-full">
              {selectCategory &&
                selectCategory.map((categoryFolder) => (
                  <TabsTrigger
                    key={categoryFolder.title}
                    value={categoryFolder.title}
                    className="px-6 rounded-3xl text-gray-600 hover:bg-gray-200 data-[state=active]:bg-gray-300 data-[state=active]:text-black data-[state=active]:font-semibold"
                  >
                    {categoryFolder.title}
                  </TabsTrigger>
                ))}
            </TabsList>

            {selectCategory &&
              selectCategory.map((categoryFolder) => (
                <TabsContent key={categoryFolder.title} value={categoryFolder.title}>
                  {renderFolderContents([categoryFolder], false)}
                </TabsContent>
              ))}

          </Tabs>

          {(selectCategory === null || selectCategory === undefined || selectCategory.length === 0)&& (
            <div className="flex text-sm  justify-center items-center w-full text-gray-500">
              <Info className="mr-2"></Info>
              Please select folder content path from category</div>
          )}


          <div className="flex flex-row justify-end items-end space-x-2">
            <Button size="lg" variant="outline" onClick={closeDialog}>
              Close
            </Button>
            <Button
              size="lg"
              onClick={SavePath}
            >
              Save Path(s){" "}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>


  );
};

export default CategoryPop;