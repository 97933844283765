import React, { memo, useEffect, useRef, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../../core/components/ui/card';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog";
import { Button } from '../../../core/components/ui/button';
import { DataTable } from '../../../shared/components/data-table/data-table';
import { Plus, Trash, PencilIcon } from 'lucide-react';
import { toast } from 'react-toastify';
import { Input } from '../../../core/components/ui/input';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../../../core/components/ui/alert-dialog';
import { Label } from '../../../core/components/ui/label';
import { getCategoryConfig, saveCategoryConfig, deleteCategoryConfig } from '../../../shared/services/categoryConfig';
import { AppDispatch, RootState } from '../../../redux-store/stores/store';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnDef } from '@tanstack/react-table';
import { Categories } from '../../../shared/models/categoryConfig-model';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../core/components/ui/select';
import { getConnectedDrive } from '../../../shared/services/userSetting';
import { Textarea } from '../../../core/components/ui/textarea';
import { Switch } from '../../../core/components/ui/switch';
import { Controller, useForm } from 'react-hook-form';

const CategoryConfig = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<any>(null);
  const getCatogeryCalled = useRef(false);
  const dispatch: AppDispatch = useDispatch();
  const { categories } = useSelector((state: RootState) => state.categories);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const { integrationList } = useSelector((state: RootState) => state.userSetting);



  useEffect(() => {
    if (!getCatogeryCalled.current) {
      dispatch(getCategoryConfig())
      dispatch(getConnectedDrive());
      getCatogeryCalled.current = true;
    }
  }, [dispatch])

  const { register, setValue, reset, control, watch, handleSubmit, getValues, formState: { errors,isValid } } = useForm({
    defaultValues: {
      title: '',
      categoryFolders: [{ title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }],
      prompt: '',
      isCollate: false
    }
  });

  const title = watch('title', '');
  const isCollate = watch('isCollate', false);
  const folders = watch('categoryFolders', [{ title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }]);

  const openAddDialog = () => {
    resetForm();
    setCurrentCategory(null);
    setIsEditing(false);
    setIsDialogOpen(true);
  };

  const openEditDialog = (category: any) => {
    console.log(category)
    setValue('title', category.title);
    setValue('categoryFolders', category.categoryFolders);
    setValue('prompt', category.prompt);
    setValue('isCollate', category.isCollate);

    setCurrentCategory(category);
    setIsEditing(true);
    setIsDialogOpen(true);
  };

  const resetForm = () => {
    reset({
      title: '',
      categoryFolders: [{ title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }],
      prompt: '',
      isCollate: false
    })
  };

  const handleSave = (data: any) => {

    data.categoryFolders = data.categoryFolders.map((item: any) => {
      item.paths = [];
      return item
    })

    const updatedCategory = { ...currentCategory, ...data };

    dispatch(saveCategoryConfig(updatedCategory))
      .then(() => {
        if (isEditing && currentCategory) {
          toast.success('Category updated successfully');
        } else {
          toast.success("Category added successfully");
        }
        setIsDialogOpen(false);
      })
      .catch((error) => {
        if (isEditing && currentCategory) {
          toast.error('Error updating category', error);
        } else {
          toast.error('Error Category Added', error);
        }
        setIsDialogOpen(false);
      });

  };

  const handleDelete = (categoryId: number) => {
    dispatch(deleteCategoryConfig(categoryId))
      .then(() => {
        toast.success('Category deleted successfully');
      })
      .catch((error) => {
        toast.error('Error deleting category: ' + error);
      });
  };

  useEffect(() => {
    const isTitleAndFoldersFilled = folders.some(folder => folder.folder?.trim() !== '');

    if (isCollate) {
      setIsSaveButtonDisabled!(isTitleAndFoldersFilled);
    } else {
      setIsSaveButtonDisabled(!isTitleAndFoldersFilled);
    }
  }, [folders, prompt]);

  const addFolder = () => setValue('categoryFolders', [...folders ?? [], { title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }]);


  const getColumns = (
    handleDelete: (value: any) => void,
    openEditDialog: (value: any) => void
  ): ColumnDef<Categories, any>[] => [
      {
        accessorKey: "id",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            ID
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: memo(({ row }) => (
          <div className="flex ml-10">{row.getValue("id")}</div>
        )),
      },
      {
        accessorKey: "title",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Title
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: memo(({ row }) => (
          <div className="flex ml-10">{row.getValue("title")}</div>
        )),
      },
      {
        id: 'actions',
        cell: ({ row }: any) => {
          const Categories = row.original;
          return (
            <div className="flex flex-row items-center justify-end space-x-2 mr-6">
              <Button variant="outline" size="icon" onClick={() => openEditDialog(Categories)} className="border-blue-500 rounded-full" id="s:categoryConfig-table-edit_button">
                <PencilIcon className="size-5" color='black' />
              </Button>
              <ConfirmDeleteButton onConfirm={() => handleDelete(Categories.id)} />
            </div>
          );
        },
      },
    ];

  const removeFolder = (index: number) => {
    if (folders.length > 1) {
      const updatedFolders = folders.filter((_, i) => i !== index);
      setValue('categoryFolders', updatedFolders);
    }
  };


  return (
    <div className="flex flex-row md:flex-row w-full overflow-auto gap-6 p-6 h-100vh">
      <Card className="w-full md:w-[calc(100%-1.5rem)] h-fit">
        <CardHeader>
          <CardTitle className="flex w-full flex-row items-start md:items-center justify-between">
            <p className="text-xl font-bold mb-2">Category Configuration</p>
            <Button size="lg" onClick={openAddDialog} className="space-x-2">
              <Plus className="size-5" /> <p className="hidden md:flex" id="s:categoryConfig-addNewCategory_button">Add New Category</p>
            </Button>
          </CardTitle>
          <CardDescription className='font-bold'>Manage Categories</CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col justify-center items-center">
          <DataTable data={categories} getColumns={() => getColumns(handleDelete, openEditDialog)} />
        </CardContent>
      </Card>

      {/* Add/Edit Category Dialog */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="w-full sm:max-w-[1300px] p-4 sm:p-6 rounded-md bg-white max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>{isEditing ? 'Edit Category' : 'Add New Category'}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit(handleSave)}>
            <div className="space-y-3">
              <Label className="block text-sm font-medium">Title</Label>
              <Input
                id="s:categoryConfig-title-input_field"
                className={`mt-1 block w-full p-2 border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded`}
                {...register("title",
                  {
                    required: 'Title is required',
                  })}
                placeholder="Enter title"
              />
              {errors.title && (
                <p className="text-red-500 text-sm">{errors.title.message}</p>
              )}
              <Label className="block text-sm font-medium">Folders</Label>
              {folders?.map((folder, index) => (
                <div key={index} className="flex flex-col sm:flex-row sm:space-x-1 pb-3"> {/* Adjust layout for mobile */}
                  <Controller
                    name={`categoryFolders.${index}.title`}
                    control={control}
                    rules={{
                      required: "Folder title is required",
                    }}
                    render={({ field }) => (
                      <Input
                        id="s:categoryConfig-folderTitle-input_field"
                        className={`mt-1 block w-full p-1 border ${errors.categoryFolders?.[index]?.title ? 'border-red-500' : 'border-gray-300'} rounded`}
                        {...field}
                        placeholder="Enter title"
                      />
                    )}
                  />
                  {errors.categoryFolders?.[index]?.title && (
                    <p className="absolute mt-11 text-red-500 text-sm">
                      {errors.categoryFolders?.[index]?.title?.message}
                    </p>
                  )}


                  <Controller
                    name={`categoryFolders.${index}.description`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        id="s:categoryConfig-folderDescription-input_field"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                        {...field}
                        value={folder.description ?? ''}
                        placeholder="Enter Description"
                      />
                    )}
                  />
                  <Controller
                    name={`categoryFolders.${index}.integration`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        value={folder?.integration ?? ''}
                        onValueChange={(item: string) => setValue(`categoryFolders.${index}.integration`, item)}
                      >
                        <SelectTrigger className="w-full sm:max-w-[500px] md:max-w-[180px] mt-1 rounded-2xl">
                          <SelectValue>
                            {integrationList.find(ele => ele.id.toString() === folder.integration.toString())?.name || "Select an Integration"}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                          {integrationList && integrationList.length > 0 ? (
                            integrationList.map((item, indext) => (
                              <SelectItem key={indext} value={item.id.toString()}>{item.name}</SelectItem>
                            ))
                          ) : (
                            <li>No integration available</li>
                          )}
                        </SelectContent>
                      </Select>
                    )}
                  />

                  <Controller
                    name={`categoryFolders.${index}.folder`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        id="s:categoryConfig-folderName-input_field"
                        {...field}
                        value={folder.folder ?? ''}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                        placeholder="Folder name"
                      />
                    )}
                  />

                  <Controller
                    name={`categoryFolders.${index}.isDefault`}
                    control={control}
                    render={({ field }) => (
                      <Label className="flex flex-col items-center space-y-1 mt-1">
                        <Switch
                          id={`categoryFolders.${index}.isDefault`}
                          checked={folder.isDefault}
                          onClick={() => setValue(`categoryFolders.${index}.isDefault`, !folder.isDefault)}
                        />
                        <span className="text-xs">Default</span>
                      </Label>
                    )}
                  />

                  <Controller
                    name={`categoryFolders.${index}.isReference`}
                    control={control}
                    render={({ field }) => (
                      <Label className="flex flex-col items-center space-y-1 mt-1 pl-1">
                        <Switch
                          id={`categoryFolders.${index}.isReference`}
                          checked={folder.isReference}
                          onClick={() => setValue(`categoryFolders.${index}.isReference`, !folder.isReference)}
                        />
                        <span className="text-xs">Attachment</span>
                      </Label>
                    )}
                  />

                  {folders?.length > 1 && (
                    <Controller
                      name={`categoryFolders.${index}.title`}
                      control={control}
                      render={({ field }) => (
                        <Button
                          id="s:categoryConfig-form-newFolder-delete_button"
                          size="icon"
                          variant="outline"
                          onClick={(e) => { removeFolder(index); e.preventDefault() }}
                          className="border-red-500 p-2 rounded-full"
                        >
                          <Trash className="size-5" />
                        </Button>
                      )}
                    />
                  )}
                </div>
              ))}


              <Button size="sm" onClick={(e) => { addFolder(); e.preventDefault() }} className="space-x-2 mt-2" id="s:categoryConfig-form-newFolder_button">
                <Plus className="size-5" /> <p className="hidden md:flex">New Folder</p>
              </Button>

              <div>
                <div className="flex flex-row gap-2">
                  <Switch
                    id="collate"
                    {...register("isCollate")}
                    checked={isCollate}
                    onCheckedChange={(val) => setValue('isCollate', val)}
                  />
                  <label htmlFor="collate" className="block text-sm font-medium">Collate</label>
                </div>

                {isCollate && (
                  <Textarea
                    id="prompt"
                    className="mt-4 block w-full p-2 border border-gray-300 rounded min-h-[100px] h-auto"
                    {...register("prompt")}
                    placeholder="Enter Your Prompt"
                  />
                )}
              </div>
            </div>

            <div className="flex justify-end mt-4 space-x-2">
              <Button size='lg' variant='outline' onClick={(e) => { setIsDialogOpen(false); e.preventDefault() }}>Cancel</Button>
              <Button size='lg' type='submit' id="s:categoryConfig-form-update/save_button"  disabled={!isValid}>
                {isEditing ? 'Update' : 'Save'}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const ConfirmDeleteButton = ({ onConfirm }: { onConfirm: () => void }) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button size="icon" variant="outline" className="border-secondary rounded-full" id="s:categoryConfig-table-delete_button">
          <Trash className="size-5" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Category</AlertDialogTitle>
          <AlertDialogDescription>Are you sure you want to delete this category?</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            <Button variant="ghost" id="s:categoryConfig-table-delete-cancel_button">Cancel</Button>
          </AlertDialogCancel>
          <AlertDialogAction>
            <Button variant="default" onClick={onConfirm} id="s:categoryConfig-table-delete-confirm_button">Delete</Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CategoryConfig;


