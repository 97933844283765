import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../redux-store/stores/store"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../core/components/ui/card"
import { useEffect, useState } from "react"
import { fetchCompanyList, SwitchCompnay } from "../../../shared/services/manager"
import { ResetPassword } from "../reset-password/reset-password"
import { Button } from "../../../core/components/ui/button"
import { Briefcase, EditIcon, MailIcon, PhoneIcon, UserIcon } from "lucide-react"
import TeamMember from "../workspaceManagement/teamMember"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../core/components/ui/tooltip"
import { RefreshAuthToken } from "../../../shared/services"
import { resetSuccess } from '../../../redux-store/reducers/dashboard-reducer';
import { Company } from "../../../shared/models/user-state"
import { cn } from "../../../core/lib/utils"
import { resetChatList, resetSelectedChat } from "../../../redux-store/reducers/chat-reducers"


interface Workspace {
    name: string;
    id: number;
    clientId: number;
    isActive: boolean
}

export const General = () => {
    const { userInfo } = useSelector((state: RootState) => state.auth);
    const dispatch: AppDispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [workspaceList, setWorkspaceList] = useState<Workspace[]>([]);
    const [profileEditMode, setProfileEditMode] = useState(false);
    const [setedUser, setUser] = useState<any | null>(null);



    useEffect(() => {

        loadCompanies();
    }, []);

    const refresh = async () => {
        const refreshed = await RefreshAuthToken();
        if (refreshed) {
            dispatch(resetSuccess(false))
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }


    useEffect(() => {
        if (userInfo) {
            // console.log("User Info has changed:", userInfo);
            setUser(userInfo);
        }
    }, [userInfo]);
    const loadCompanies = async () => {
        const companyList = await fetchCompanyList();
        const companyArray: Company[] = Array.from(companyList.values());

        const fetchCompanyDetails: Workspace[] = companyArray.map((company) => ({
            name: company.companyName,
            id: parseInt(company.companyId),
            clientId: parseInt(company.clientId),
            isActive: company.isActive
        }));
        setWorkspaceList(fetchCompanyDetails);
    }

    const handleDialog = () => {
        setOpen(!open);
    };

    const toggleEditMode = (userDetails: any) => {
        setUser(userDetails);
        setProfileEditMode(true);
        handleDialog();
    };

    const switchCompany = (company: Workspace) => {
        SwitchCompnay(company.id)
        loadCompanies();
        dispatch(resetChatList())
        dispatch(resetSelectedChat())
    }

    return (
        <div className="flex flex-col md:flex-row gap-4 p-6 w-full h-100vh">
            <div className="w-full lg:w-8/12">
                <Card className="shadow-xl" id="card1">
                    <CardHeader>
                        <CardTitle id="s:setting-general-workspace-title">
                            <p className="text-xl font-bold">Workspace</p>
                        </CardTitle>
                        <CardDescription id="s:setting-general-workspace-description" className='font-bold'>
                            The list of our workspaces is provided below.
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="flex flex-col w-full justify-center items-center p-4">
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-4 w-full">
                            {workspaceList.map((item, index) => (
                                <Card
                                    key={index}
                                    className={cn(
                                        "m-2 bg-gray-200 rounded-lg flex flex-col items-center justify-center hover:cursor-pointer",
                                        item.isActive ? 'border-2 border-green-300' : ''
                                    )}
                                    onClick={() => switchCompany(item)}
                                >
                                    <div className="w-16 h-16 md:w-20 md:h-20 rounded-full flex justify-center items-center uppercase text-3xl md:text-5xl">
                                        <p className="text-black font-extrabold" id="s:setting-general-workspace-company_letter">
                                            {item.name.charAt(0)}
                                        </p>
                                    </div>
                                    <div className="flex justify-center items-center w-full p-2">
                                        <p className="text-center text-sm md:text-md text-wrap break-words" id="s:setting-general-workspace-company_name">
                                            {item.name}
                                        </p>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </CardContent>

                </Card>
            </div>
            <div className="space-y-4 w-full lg:w-4/12 h-full">
                <div className="flex flex-col w-full h-50 items-center justify-start bg-card rounded-xl shadow-xl p-4 overflow-hidden">
                    <div className="flex flex-col w-full h-9 items-center justify-center bg-spot-gradient relative rounded-t-xl">
                        <div className="flex rounded-full w-9 h-9 shadow-md items-center justify-center top-3 absolute bg-white">
                            {
                                userInfo?.profilePicture ?
                                    <img
                                        src={userInfo?.profilePicture}
                                        alt="Avatar"
                                        className="w-9 h-9 rounded-full object-center"
                                    />
                                    :
                                    <p className="font-semibold capitalize" id="s:setting-general-profileInfo-user_letter">{userInfo?.userName?.substring(0, 1)}</p>
                            }
                        </div>
                    </div>
                    <div className="flex flex-col w-full py-4 px-2  space-y-1 mt-2">
                        <div className="flex flex-col relative">
                            <div className="flex flex-col relative justify-end items-end">
                                <div className="flex flex-col justify-end items-end absolute top-0.5 px-2 space-y-1">
                                    <div className="font-semibold text-sm">
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        size={"icon"}
                                                        variant="outline"
                                                        className="border-gray-500 rounded-full hover:border-blue-500"
                                                        onClick={() => toggleEditMode && toggleEditMode(userInfo)

                                                        }
                                                    >
                                                        <EditIcon className="size-5" color="black" />
                                                    </Button>
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    <p>Edit User Info</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-start items-start">
                                <p className="flex text-sm secondary pt-1">
                                    <UserIcon id="s:setting-general-profileInfo-user_icon"/>
                                    <span className="pl-2 pt-1 capitalize" id="s:setting-general-profileInfo-user_name">
                                        {userInfo?.userName?.trim() ? userInfo.userName : 'N/A'}
                                    </span>
                                </p>
                                <p className="flex text-sm secondary pt-2">
                                    <MailIcon id="s:setting-general-profileInfo-mail_icon"/>
                                    <span className="pl-2 pt-1" id="s:setting-general-profileInfo-user_email">
                                        {userInfo?.userEmail?.trim() ? userInfo.userEmail : 'N/A'}
                                    </span>
                                </p>
                                <p className="flex text-sm secondary pt-2">
                                    <PhoneIcon id="s:setting-general-profileInfo-mobile_icon"/>
                                    <span className="pl-2 pt-1" id="s:setting-general-profileInfo-user_mobileNo">
                                        {userInfo?.userMobileNumber?.trim() ? userInfo.userMobileNumber : 'N/A'}
                                    </span>
                                </p>
                                <p className="flex text-sm secondary pt-2" id="s:setting-general-profileInfo-briefcase_icon">
                                    <Briefcase />
                                    <span className="pl-2 pt-1" id="s:setting-general-profileInfo-user_jobTitle">
                                        {userInfo?.userJobTitle?.trim() ? userInfo.userJobTitle : 'N/A'}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <ResetPassword />
            </div>
            <TeamMember
                open={open}
                setOpen={setOpen}
                userValues={setedUser}
                profileEdit={profileEditMode}
            />
        </div>
    );
}