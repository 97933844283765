import React, { useEffect, useRef, useState } from 'react';
import { Card, CardDescription, CardHeader, CardTitle } from "../../../core/components/ui/card";
import { Input } from '../../../core/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../core/components/ui/select';
import { Button } from "../../../core/components/ui/button"; // Assuming the Button component is imported here
import { Info, Plus, Trash } from 'lucide-react'; // Assuming you're using these icons from lucide-react
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../../../core/components/ui/dialog';
import { getConnectedDrive } from '../../../shared/services/userSetting';
import { AppDispatch, RootState } from '../../../redux-store/stores/store';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrationConfig, saveIntegrationConfig, deleteIntegrationConfig } from '../../../shared/services/integrationConfig';
import { toast } from 'react-toastify';



interface ConnectedDrive {
    id: string;
    pluginId: string;
    name: string;
  }


const IntegrationConfig = () => {

    const dispatch: AppDispatch = useDispatch()
    const getConnectedDriveCalled = useRef(false);
    const [connectedDrives, setConnectedDrives] = useState<ConnectedDrive[]>([]);
    const getIntegrationConfigCalled = useRef(false);
    const { integrationConfig } = useSelector((state: RootState) => state.integrationConfig);
    
    useEffect(() => {
        if (!getConnectedDriveCalled.current) {
          dispatch(getConnectedDrive()).then((response) => {
              setConnectedDrives(response.payload);
          });
          getConnectedDriveCalled.current = true;
        }
      }, [dispatch]);

      useEffect (() => {
        if(!getIntegrationConfigCalled.current){
            dispatch(getIntegrationConfig())
            getIntegrationConfigCalled.current = true;
        }
      }, [dispatch])

      useEffect(() =>{
        if(integrationConfig && integrationConfig.length > 0){
            const updateRow = integrationConfig.map(configs => ({
                maxFilesSize: configs.maxFilesSize.toString(),
                paths: configs.paths.join(","),
                integrationId: configs.integrationId
            }))
            setRows(updateRow)
        }
      }, [integrationConfig])

  //store multiple rows
  const [rows, setRows] = useState([
    { maxFilesSize: '', paths: '', integrationId: '' }
  ]);

  //add a new row
  const addRow = () => {
    setRows([...rows, { maxFilesSize: '', paths: '', integrationId: '' }]);
  };

  //remove a row
  const removeRow = (index: number, integrationId: string) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    if(integrationId){
      dispatch(deleteIntegrationConfig(integrationId)).then(() => {
        toast.success('Configuration Deleted Successfully');
    })
    .catch((error) => {
        toast.error('Error Deleting Configuration', error);
    });
    }
    setRows(updatedRows);
  };

  // Function to handle input changes
  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    setRows(updatedRows);
  };

  const saveConfigurations = () => {
    const IntegrationConfigModel = rows
      .filter(row => row.integrationId && row.maxFilesSize && row.paths.length > 0)
      .map(row => ({
        maxFilesSize: parseInt(row.maxFilesSize, 10), 
        paths: row.paths.split(','), 
        integrationId: row.integrationId
      }));

    if (IntegrationConfigModel.length > 0) {
        dispatch(saveIntegrationConfig(IntegrationConfigModel)).then(() => {
          toast.success('Configuration Added Successfully');
      })
      .catch((error) => {
          toast.error('Error Adding Configuration', error);
      });
    }
};


  return (
    <div className="flex flex-col w-full overflow-auto p-6 h-full">
      <Card className="w-full h-fit">
        <CardHeader>
          <CardTitle className="flex w-full flex-row items-start md:items-center justify-between">
            <label className="text-xl font-bold mb-2">Configurations</label>
          </CardTitle>
          <CardDescription>
              <label className="flex flex-row w-full items-center justify-between font-bold">
              Manage integration configurations.
              </label>
        </CardDescription>
        </CardHeader>
        
        <div className="flex flex-col gap-4 px-6 lg:p-6 w-full">

          {/* Labels displayed only once */}
          <div className="flex w-full gap-4">
            <div className="hidden sm:block w-1/5">
              <div className="block font-bold mb-2 text-sm lg:text-base">Select Integration</div>
            </div>
            <div className="hidden sm:block w-1/5">
              <div className="block font-bold mb-2 text-sm lg:text-base">Max Files Size</div>
              <span className='block font-light text-[12px]'>Only transfer files smaller than this in <br/>KiB or suffix B | K | M | G | T | P</span>
            </div>
            <div className="sm:flex w-3/5 flex items-center justify-between">
              <div className="hidden sm:block font-bold mb-2 text-sm lg:text-base">File Format, Folder Path & Part of File Name</div>
              {/* Info button for instructions */}
              <div>
                <Dialog>
                <DialogTrigger asChild>
                  <Button size="icon" variant="ghost" className="ml-2">
                    <Info className="size-5" />
                  </Button>
                </DialogTrigger>
                <DialogContent className="max-w-[90vw] md:max-w-[600px] w-full p-4 md:p-6 bg-white rounded-md shadow-lg">
                <DialogTitle className="text-lg md:text-xl font-semibold">Instructions for Including and
                  <span className="hidden md:inline">
                    Excluding Paths
                  </span>
                  <span className="block md:hidden">
                    Excluding Paths
                  </span>
                </DialogTitle>
                  <DialogDescription className="text-sm md:text-base mt-2">
                    <p>To <strong>exclude</strong> items, use:</p>
                    <ul className="list-disc ml-6 mt-2 space-y-2">
                      <li>To exclude a specific file:
                      <strong>- filename.txt</strong></li>
                      <li>To exclude all files of a type:
                      <strong>- *.jpg</strong></li>
                      <li>To exclude a folder and its contents:
                      <strong>- /foldername/**</strong></li>
                    </ul><br/>
                    <div>To <strong>include</strong> items, use:</div>
                    <ul className="list-disc ml-6 mt-2 space-y-2">
                      <li>To include only files of a type:
                      <strong>+ *.png</strong></li>
                      <li>To include a specific folder:
                      <strong>+ /myfolder/**</strong></li>
                    </ul><br/>
                    <div>To <strong>combine both</strong> you can use:</div>
                    <ul className="list-disc ml-6 mt-2 space-y-2">
                      <li>Include .txt files but exclude everything else: <strong>+ *.txt followed by - *</strong></li>
                      <li>Use commas to separate items one by one.</li>
                    </ul><br/>

                    <div>Use these patterns in your input for precise control over what to include or exclude.</div>
                  </DialogDescription>
                </DialogContent>
              </Dialog>
              </div>
              
            </div>
          </div>

          {/* Dynamic rows */}
          {rows.map((row, index) => (
            <div key={index} className="flex flex-col md:flex-row w-full items-center gap-4 mb-4">
              {/* Select Integration */}
              <div className="w-full md:w-1/5">
              <span className="block sm:hidden font-bold mb-2 text-sm">Select Integration</span>
              <Select value={row.integrationId} onValueChange={(value) => handleInputChange(index, 'integrationId', value)} >
              <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select an Integration" />
                  </SelectTrigger>
                  <SelectContent>
                    {connectedDrives.map((drive) => (
                      <SelectItem key={drive.id} value={drive.id}>
                        {drive.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
  
              {/* Max File Size */}
              <div className="w-full md:w-1/5">
              <span className="block sm:hidden font-bold text-sm">Max Files Size</span> 
              <span className='block sm:hidden font-light text-[10px] mb-2'>Only transfer files smaller than this in KiB or suffix B|K|M|G|T|P</span>
                <Input
                id="s:integration-configuration-form-maxFileSize-input-field"
                  value={row.maxFilesSize}
                  className="block w-full p-2 border border-gray-300 rounded"
                  onChange={(e) => handleInputChange(index, 'maxFilesSize', e.target.value)}
                  placeholder="Enter max file size"
                />
              </div>
  
              {/* File Format */}
              <div className="w-full md:w-3/5">
              <span className="block sm:hidden font-bold mb-2 text-sm">File Format, Folder Path & File Name</span>
                <Input
                id="s:integration-configuration-form-fileFormat-input-field"
                  value={row.paths}
                  className="block w-full p-2 border border-gray-300 rounded"
                  onChange={(e) => handleInputChange(index, 'paths', e.target.value)}
                  placeholder="Enter File Format, Folder Path & Part of File Name"
                />
              </div>

              {/* Remove Button */}
              <Button
                size="icon"
                variant="outline"
                onClick={() => removeRow(index, row.integrationId)} 
                className="border-red-500 rounded-full"
              >
                <Trash className="size-5" />
              </Button>
            </div>
          ))}

        <div className='flex flex-row items-center space-x-4 ml-auto pb-6'>
          <Button size="lg" onClick={addRow} className="space-x-2 mt-2">
            <Plus className="size-5" />
            <p className="hidden md:flex">Add Row</p>
          </Button>

          <Button size='lg' onClick={saveConfigurations} className="space-x-2 mt-2">
            Save
          </Button>
        </div>


        </div>
      </Card>
    </div>
  );
};

export default IntegrationConfig;
