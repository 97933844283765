import { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../../core/components/ui/card';
import { Button } from '../../../core/components/ui/button';
import { AppDispatch, RootState } from '../../../redux-store/stores/store';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../../../core/components/ui/input';
import { getSearchCockpit, updateCockpit } from '../../../shared/services/searchCockpit';
import { Label } from '../../../core/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../core/components/ui/select"
import { toast } from 'react-toastify';


export const SearchCockpitManagement = () => {
    const [prompt, setPrompt] = useState<string>('');
    const dispatch: AppDispatch = useDispatch();
    const [maxFilesCountForChat, setMaxFilesChat] = useState(5);
    const [maxFilesCountForSearch, setMaxFilesSearch] = useState(5);
    const [searchType, setSearchType] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [modelTemperature, setModelTemperature] = useState(0.5);
    const [searchAlgorithm, setSearchAlgorithm] = useState(0);
    const [threshold, setThreshold] = useState(0.5);
    const [travilyApiKey, setTravilyApiKey] = useState('');
    const getSearchCockpitDetails = useRef(false);
    const { searchCockpit } = useSelector((state: RootState) => state.searchCockpit);
    const [maxWebSearchResult, setMaxWebSearchResult] = useState(5);
    const [chatModel, setChatModel] = useState(0);
    const [numberOfPagesSummaries, setNumberOfPagesSummaries] = useState(0);

    useEffect(() => {
        if (!getSearchCockpitDetails.current) {
            dispatch(getSearchCockpit());
            getSearchCockpitDetails.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (searchCockpit) {
            setPrompt(searchCockpit.prompt);
            setThreshold(searchCockpit.searchAlgorithmThreshold);
            setSearchAlgorithm(searchCockpit.searchAlgorithmType);
            setTravilyApiKey(searchCockpit.travilyAPIKey);
            setMaxFilesChat(searchCockpit.maxFilesReferencesForChat);
            setMaxFilesSearch(searchCockpit.maxFilesReferencesForFileSearch);
            setModelTemperature(searchCockpit.modelTemperature);
            setSearchType(searchCockpit.contentSegmentationType);
            setMaxWebSearchResult(searchCockpit.maxWebSearchResult);
            setChatModel(searchCockpit.modelType);
            setNumberOfPagesSummaries(searchCockpit.contentSegmentationCountSummary);
            setNumberOfPages(searchCockpit.contentSegmentationCount);

        }
    }, [searchCockpit]);

    // useEffect(() => {
    //     switch (searchAlgorithm) {
    //         case 0: // Cosine
    //         case 1: // Dot Product
    //             setThreshold(0.5);
    //             break;
    //         case 2: // L2 Distance
    //             setThreshold(5);
    //             break;
    //         case 3: // L1 Distance
    //             setThreshold(50);
    //             break;
    //         default:
    //             setThreshold(0.5);
    //     }
    // }, [searchAlgorithm]);

    const handleSave = () => {
        const settings = {

            Prompt: prompt,
            TravilyAPIKey: travilyApiKey,
            SearchAlgorithmType: searchAlgorithm,
            SearchAlgorithmThreshold: threshold,
            ModelTemperature: modelTemperature,
            MaxFilesReferencesForChat: maxFilesCountForChat,
            MaxFilesReferencesForFileSearch: maxFilesCountForSearch,
            ContentSegmentationType: searchType,
            ContentSegmentationCount: numberOfPages,
            MaxWebSearchResult: maxWebSearchResult,
            ModelType: chatModel,
            ContentSegmentationCountSummary: numberOfPagesSummaries,
        };

        dispatch(updateCockpit(settings)).then(() => {
            toast.success('Configuration updated successfully');
        })
        .catch((error) => {
            toast.error('Error updating category', error);
        });

    };

    return (
        <div className="flex flex-col lg:flex-row gap-4 p-6 w-full">
            <div className="lg:w-1/2 flex flex-col gap-4">
                {/* promt */}
                <Card className="shadow-xl">
                    <CardHeader>
                        <CardTitle>Prompt Input</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <Label htmlFor="promptInput" className="block font-medium mb-2">Prompt:</Label>
                        <textarea
                            id="promptInput"
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            placeholder="Enter your prompt along with any specific instructions..."
                            className="border border-gray-300 p-2 w-full h-32 rounded-xl"
                            aria-describedby="promptHelp"
                        />
                        <p id="promptHelp" className="text-sm text-gray-500 mt-2">
                            Include any additional instructions on formatting or specific details needed in the final answer.
                        </p>
                    </CardContent>
                </Card>
                <div>
                    {/* Search Algorithm */}
                    <Card className="shadow-xl ">
                        <CardHeader>
                            <CardTitle>Search Algorithm Settings</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <Label htmlFor="algorithmSelect" className="block font-medium mb-2">Select Algorithm:</Label>
                            <Select
                                value={String(searchAlgorithm)}
                                onValueChange={(value) => {
                                    const algorithm = parseInt(value, 10);
                                    setSearchAlgorithm(algorithm);
                                    
                                    // Update threshold when searchAlgorithm changes
                                    if (algorithm === 2) {
                                        setThreshold(5);
                                    } else if (algorithm === 3) {
                                        setThreshold(50);
                                    } else {
                                        setThreshold(0.5);
                                    }
                                }}
                            >
                                <SelectTrigger className="border border-gray-300 p-2 rounded-full">
                                    <SelectValue placeholder="Select an algorithm" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="0">Cosine Similarity</SelectItem>
                                    <SelectItem value="1">Dot Product</SelectItem>
                                    <SelectItem value="2">L2 Distance</SelectItem>
                                    <SelectItem value="3">L1 Distance</SelectItem>
                                </SelectContent>
                            </Select>
                            <Label htmlFor="thresholdSlider" className="block font-medium mt-4 mb-2">Threshold ({searchAlgorithm === 2 ? "0 to 10" : searchAlgorithm === 3 ? "0 to 100" : "0 to 1"}):</Label>
                            <Input
                                id="thresholdSlider"
                                type="range"
                                min={searchAlgorithm === 2 ? 0 : searchAlgorithm === 3 ? 0 : 0}
                                max={searchAlgorithm === 2 ? 10 : searchAlgorithm === 3 ? 100 : 1}
                                step={searchAlgorithm === 2 || searchAlgorithm === 3 ? 1 : 0.01}
                                value={threshold}
                                onChange={(e) => setThreshold(parseFloat(e.target.value))}
                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                            />
                            <div className="text-right mt-2">
                                <span className="text-sm font-semibold">{threshold.toFixed(searchAlgorithm === 2 || searchAlgorithm === 3 ? 0 : 2)}</span>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            
                <Card className="shadow-xl w-2/2 mt-1">
                    <CardHeader>
                        <CardTitle>Chat Models</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="flex flex-col gap-2 mb-1">
                            <Label htmlFor="segmentType" className="font-medium mb-2">Select Chat Model</Label>
                            <Select
                                value={String(chatModel)}
                                onValueChange={(value) => setChatModel(Number(value))}
                            >
                                <SelectTrigger className='rounded-full'>
                                    <SelectValue placeholder="Select a model" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="0">NOVA</SelectItem>
                                    <SelectItem value="1">ORION</SelectItem>
                                    {/* <SelectItem value="2">ZEUS</SelectItem> */}
                                </SelectContent>
                            </Select>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div className="lg:w-1/2 flex flex-col gap-4">
                <div className='flex flex-inline gap-4'>
                    {/* Max Files in Results Card */}
                    <Card className="shadow-xl w-1/2">
                        <CardHeader>
                            <CardTitle>Max Files References For Chat</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <Input
                                id="s:setting-searchCockpit-maxFRF_chat_inputField"
                                type="number"
                                value={maxFilesCountForChat}
                                onChange={(e) => setMaxFilesChat(Number(e.target.value))}
                                placeholder="Enter maximum file count"
                                className="border border-gray-300 p-2 w-full mt-2"
                                min="1"

                            />
                        </CardContent>
                    </Card>

                    <Card className="shadow-xl w-1/2">
                        <CardHeader>
                            <CardTitle>Max Files References For File Search</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <Input
                                id="s:setting-searchCockpit-maxFRF_fileSearch_inputField"
                                type="number"
                                value={maxFilesCountForSearch}
                                onChange={(e) => setMaxFilesSearch(Number(e.target.value))}
                                placeholder="Enter maximum file count"
                                className="border border-gray-300 p-2 w-full mt-2"
                                min="1"

                            />
                        </CardContent>
                    </Card>
                </div>

                <div className='flex flex-inline gap-4'>

                    <Card className="shadow-xl w-1/2">
                        <CardHeader>
                            <CardTitle>Max Web Search Result</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <Input
                                id="s:setting-searchCockpit-maxWSR_inputField"
                                type="number"
                                value={maxWebSearchResult}
                                onChange={(e) => setMaxWebSearchResult(Number(e.target.value))}
                                placeholder="Enter maximum web search results"
                                className="border border-gray-300 p-2 w-full mt-2"
                                min="0"
                            />
                        </CardContent>
                    </Card>
                    <Card className="shadow-xl w-1/2">
                        <CardHeader>
                            <CardTitle>Tavily API Key</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <Input
                                id="s:setting-searchCockpit-tavily_api_key_inputField"
                                type="text"
                                placeholder="Enter Tavily API Key"
                                value={travilyApiKey}
                                onChange={(e) => setTravilyApiKey(e.target.value)}
                                className="w-full"
                            />
                        </CardContent>
                    </Card>

                </div>

                <div className='flex flex-col gap-4 lg:flex-row'>
                    {/* Model Temparature */}
                    <Card className="shadow-xl lg:w-1/2">
                        <CardHeader>
                            <CardTitle>Model Temperature</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <Label htmlFor="temperatureSlider" className="block font-medium mb-2">Adjust Model Temperature:</Label>
                            <Input
                                id="temperatureSlider"
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={modelTemperature}
                                onChange={(e) => setModelTemperature(parseFloat(e.target.value))}
                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                            />
                            <div className="text-right mt-2">
                                <span className="text-sm font-semibold">{modelTemperature.toFixed(2)}</span>
                            </div>
                            <p className="text-sm text-gray-600 mt-2">
                                Note: A temperature near 0 gives precise outputs; closer to 1, it encourages creativity.
                            </p>
                        </CardContent>
                    </Card>

                    {/* Content Segmentation */}
                    <Card className="shadow-xl lg:w-1/2">
                        <CardHeader>
                            <CardTitle>Content Segmentation</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="flex flex-col gap-2 pb-2">
                                <Label htmlFor="segmentType" className="font-medium">Segment by:</Label>
                                <Select
                                    value={String(searchType)}
                                    onValueChange={(value) => setSearchType(Number(value))}
                                >
                                    <SelectTrigger className='rounded-full'>
                                        <SelectValue placeholder="Select search type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="0">Pages</SelectItem>
                                        <SelectItem value="1">Chunks</SelectItem>
                                    </SelectContent>
                                </Select>

                            </div>
                            {searchType === 1 ? (
                                <div>
                                    <Label htmlFor="noOfChunks" className="block font-medium">Number of Chunks for Specific Queries:</Label>
                                    <Input
                                        id="noOfChunks"
                                        type="number"
                                        value={numberOfPages}
                                        onChange={(e) => setNumberOfPages(Number(e.target.value))}
                                        placeholder="Enter number of chunks"
                                        className="border border-gray-300 p-2 w-full mt-2"
                                        min="1"
                                    />
                                </div>
                            ) : (
                                <div>
                                    <Label htmlFor="numberOfPages" className="block font-medium">Number of Pages for Specific Queries:</Label>
                                    <Input
                                        id="numberOfPages1"
                                        type="number"
                                        value={numberOfPages}
                                        onChange={(e) => setNumberOfPages(Number(e.target.value))}
                                        placeholder="Enter number of pages"
                                        className="border border-gray-300 p-2 w-full mt-2"
                                        min="1"
                                    />
                                </div>
                            )}
                            <div className='pt-2'>
                                <Label htmlFor="numberOfPagesSummaries" className="block font-medium">Number of Pages for Summaries:</Label>
                                <Input
                                    id="numberOfPages2"
                                    type="number"
                                    value={numberOfPagesSummaries}
                                    onChange={(e) => setNumberOfPagesSummaries(Number(e.target.value))}
                                    placeholder="Enter number of pages"
                                    className="border border-gray-300 p-2 w-full mt-2"
                                    min="1"
                                />
                            </div>
                        </CardContent>
                    </Card>
                </div>


                <div className='flex flex-col justify-end items-end mt-2'>
                    <Button size='lg' onClick={handleSave} id="s:setting-searchCockpit-save_button">
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};
