import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog";
import { Card } from "../../../core/components/ui/card";
import { getConnectOathCode, getDrive } from "../../../shared/services/userSetting";
import { unwrapResult } from "@reduxjs/toolkit";
import { openCloseApiKeyDialog, setUiConfig } from "../../../redux-store/reducers/userSetting-reducers";
import boxIcon from "../../../assets/images/cloudIcon/icons8-box-480.png";
import megaIcon from "../../../assets/images/cloudIcon/mega.png";
import icloudIcon from "../../../assets/images/cloudIcon/icloud.png";
import nextCloudIcon from "../../../assets/images/cloudIcon/icons8-nextcloud-240.png";
import spideroak from "../../../assets/images/cloudIcon/spideroak.png";
import idriveIcon from "../../../assets/images/cloudIcon/idrive.png";
import pCloudIcon from "../../../assets/images/cloudIcon/icons8-pcloud-480.png";
import mediaFireIcon from "../../../assets/images/cloudIcon/MediaFire.png";
import tresoritIcon from "../../../assets/images/cloudIcon/tresorit.png";
import egnyteIcon from "../../../assets/images/cloudIcon/egnyte.png";
import sugarsyncIcon from "../../../assets/images/cloudIcon/sugarsync.png";
import openDriveIcon from "../../../assets/images/cloudIcon/open-drive.png";
import jungleDisk from "../../../assets/images/cloudIcon/jungle-disk.png";
import carbonite from "../../../assets/images/cloudIcon/carbonite.png";
import flipDriveIcon from "../../../assets/images/cloudIcon/flipdrive.png";
import fileAnyWhereIcon from "../../../assets/images/cloudIcon/fileAnyWhere.png";
import elephantDriveIcon from "../../../assets/images/cloudIcon/elephantDrive.png";
import aDriveIcon from "../../../assets/images/cloudIcon/adrive.png";
import FichierIcon from "../../../assets/images/cloudIcon/1Fichier.png";
import AkamaiIcon from "../../../assets/images/cloudIcon/Akamai.png";
import alibabaIcon from "../../../assets/images/cloudIcon/alibaba-cloud.png";
import amazonS3Icon from "../../../assets/images/cloudIcon/amazonS3.png";
import backblazeIcon from "../../../assets/images/cloudIcon/BLZE.png";
import cephIcon from "../../../assets/images/cloudIcon/ceph.png";
import cloudflareIcon from "../../../assets/images/cloudIcon/cloudflare.png";
import digitalOceanIcon from "../../../assets/images/cloudIcon/digitalOcean.png";
import dreamHostIcon from "../../../assets/images/cloudIcon/dreamhost.png";
import ionosIcon from "../../../assets/images/cloudIcon/IONOS.png";
import leviiaIcon from "../../../assets/images/cloudIcon/leviia.png";
import magaluCloudIcon from "../../../assets/images/cloudIcon/magaluCloud.png";
import minoIcon from "../../../assets/images/cloudIcon/Mino.png";
import rcloneServeS3 from "../../../assets/images/cloudIcon/RcloneServeS3.png";
import scalewayIcon from "../../../assets/images/cloudIcon/Scaleway.png";
import seaGateIcon from "../../../assets/images/cloudIcon/seagate.png";
import storjIcon from "../../../assets/images/cloudIcon/storjIcon.png";
import synologyIcon from "../../../assets/images/cloudIcon/synology.png";

interface FileDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    files?: any[] | null;
    setFiles?: React.Dispatch<React.SetStateAction<any[] | null>>;
}

const IntegrationListViewer: React.FC<FileDialogProps> = ({ open, setOpen }) => {
    const dispatch: AppDispatch = useDispatch()
    const { availableList } = useSelector((state: RootState) => state.userSetting);
    const getConnectedDriveCalled = useRef(false);


    const allDrives = [
        { title: "Box", icon: boxIcon },
        { title: "Mega", icon: megaIcon },
        { title: "Apple Icloud", icon: icloudIcon },
        { title: "Next cloud", icon: nextCloudIcon },
        { title: "SpiderOak", icon: spideroak },
        { title: "IDrive", icon: idriveIcon },
        { title: "pCloud", icon: pCloudIcon },
        { title: "MediaFire", icon: mediaFireIcon },
        { title: "Tresorit", icon: tresoritIcon },
        { title: "Egnyte", icon: egnyteIcon },
        { title: "Sugarsync", icon: sugarsyncIcon },
        { title: "OpenDrive", icon: openDriveIcon },
        { title: "Jungle Disk", icon: jungleDisk },
        { title: "Carbonite", icon: carbonite },
        { title: "FlipDrive", icon: flipDriveIcon },
        { title: "Files Anywhere", icon: fileAnyWhereIcon },
        { title: "Elephant Drive", icon: elephantDriveIcon },
        { title: "ADrive", icon: aDriveIcon },
        { title: "1Fichier", icon: FichierIcon },
        { title: "Akamai Netstorage", icon: AkamaiIcon },
        { title: "Alibaba Cloud", icon: alibabaIcon },
        { title: "Amazon S3", icon: amazonS3Icon },
        { title: "Backblaze B2", icon: backblazeIcon },
        { title: "Ceph", icon: cephIcon },
        { title: "Cloudflare R2", icon: cloudflareIcon },
        { title: "DigitalOcean Spaces", icon: digitalOceanIcon },
        { title: "Dreamhost", icon: dreamHostIcon },
        { title: "IONOS Cloud", icon: ionosIcon },
        { title: "Leviia Cloud Storage", icon: leviiaIcon },
        { title: "Magalu Storage", icon: magaluCloudIcon },
        { title: "Mino", icon: minoIcon },
        { title: "Rclone Serve S3", icon: rcloneServeS3 },
        { title: "Scaleway", icon: scalewayIcon },
        { title: "Seagate Lyve Cloud", icon: seaGateIcon },
        { title: "Storj", icon: storjIcon },
        { title: "Synology", icon: synologyIcon },
    ];

    useEffect(() => {
        if (!getConnectedDriveCalled.current && open) {
            dispatch(getDrive());
            getConnectedDriveCalled.current = true;
        }// eslint-disable-next-line
    }, [open]);


    const handleConnectIntegrtion = async (integration: any) => {
        try {
            if (integration.isOauth) {
                const resultAction = await dispatch(getConnectOathCode(integration.pluginId));
                const response = unwrapResult(resultAction);
                window.open(response.url, '_self');
            }
            if (!integration.isOauth) {
                dispatch(openCloseApiKeyDialog(true))
                dispatch(setUiConfig(integration))
                setOpen(false);
            }
        } catch (error) {
            console.error('Failed to connect integration:', error);
        }
    };

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="sm:max-w-[1200px] lg:max-h-[600px] max-h-[90vh] overflow-y-scroll w-full sm:max-w-[90%] max-w-[90%] p-4 sm:p-6 rounded-md bg-white">
                    <DialogHeader className="flex flex-col items-center">
                        <DialogTitle className="font-bold text-xl md:text-2xl lg:text-[40px]">
                            Integration List
                        </DialogTitle>
                    </DialogHeader>
                    <div className="items-center">
                        <div className="flex flex-col p-3 font-bold text-lg md:text-xl text-gray-900">
                            <DialogDescription className="text-gray-900 text-[16px] md:text-[20px]">
                                Available Integration
                            </DialogDescription>
                        </div>
                        <ul className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2">
                            {availableList.length > 0 ? (
                                availableList.map((item, index) => (
                                    <div key={index}>
                                        <Card
                                            className={`m-2 border-2 border-transparent border-blue-200 bg-gray-50 min-h-[6rem] rounded-lg transition-all duration-300 ease-in-out hover:cursor-pointer hover:shadow-xl hover:border-blue-900 flex`}
                                            onClick={() => handleConnectIntegrtion(item)}
                                        >
                                            <div className="m-1 flex flex-col w-full justify-center items-center">
                                                <div className="w-16 h-16 md:w-20 md:h-20 ml-2 rounded-lg flex justify-center items-center uppercase text-xl">
                                                    <img
                                                        src={item.icon}
                                                        alt="icon"
                                                        loading="lazy"
                                                        className="w-full h-full object-contain"
                                                    />
                                                </div>
                                                <div className="flex flex-col ml-2 font-semibold text-sm md:text-lg">
                                                    {item.title}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                ))
                            ) : (
                                <p>No integrations available</p>
                            )}
                        </ul>
                    </div>
                    <div className="items-center">
                        <div className="flex flex-col p-3 font-bold text-lg md:text-xl text-gray-900">
                            <DialogDescription className="text-gray-900 text-[16px] md:text-[20px]">
                                Available On Enterprise Plan
                            </DialogDescription>
                        </div>

                        <ul className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-2">
                            {allDrives.map((drive, index) => (
                                <div key={index}>
                                    <Card
                                        className={`m-2 border-2 border-transparent min-h-[6rem] md:min-h-[8rem] rounded-lg transition-all duration-300 ease-in-out flex bg-gray-200 opacity-70 cursor-not-allowed hover:cursor-pointer hover:shadow-xl hover:border-blue-900`}
                                    >
                                        <div className="m-1 flex flex-col w-full justify-center items-center">
                                            <div className="w-16 h-16 md:w-20 md:h-20 ml-2 rounded-lg flex justify-center items-center uppercase text-xl md:text-2xl">
                                                <img
                                                    src={drive.icon}
                                                    alt="icon"
                                                    loading="lazy"
                                                    className="w-full h-full object-contain"
                                                />
                                            </div>
                                            <div className="w-full flex flex-col justify-center items-center ml-2 mt-1 font-semibold text-xs md:text-sm">
                                                {drive.title}
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </ul>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default IntegrationListViewer;